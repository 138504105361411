import * as React from "react";

const ChevronDownIcon = ({ size = 12 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 12 7"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="M10.667 1 6 5.667 1.333 1"
    ></path>
  </svg>
);

export default ChevronDownIcon;

export const mongoliaWhyChooseUs = [
  {
    title: "Gallop through the vast fields of Mongolia",
    description: (
      <>
        “One of the <span className="tw-text-primary-5">best trips</span> &
        horse riding experience in my life”
      </>
    ),
    thumbnailSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/mongolia_horse_pic.png",
    reviewer: {
      name: "Alex",
      profilePictureSrc:
        "https://d1puf2nnbcyl9q.cloudfront.net/6fb7b0d436666805bcb8ba02bcd62d19-m",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 14%, rgba(0, 0, 0, 0) 28.5%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0.4) 77.26%)",
  },
  {
    title: "Soak in the authentic nomadic yurt experience",
    description: (
      <>
        “I really enjoyed the{" "}
        <span className="tw-text-primary-4">people and culture</span> on this
        one-of-a-kind trip!”
      </>
    ),
    thumbnailSrc: "https://d1puf2nnbcyl9q.cloudfront.net/mongolia_yurt_pic.png",
    reviewer: {
      name: "Alicia",
      profilePictureSrc:
        "https://d1puf2nnbcyl9q.cloudfront.net/34d112c61000cb4a073f21e19fe48810-m",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 21.05%, rgba(0, 0, 0, 0) 29.88%, rgba(0, 0, 0, 0) 68.91%, rgba(0, 0, 0, 0.5) 81.27%)",
  },
  {
    title: "Run across the world renown Gobi Desert",
    description: (
      <>
        “I had an amazing time{" "}
        <span className="tw-text-primary-4">riding camels</span>
        and climbing the sane dunes to{" "}
        <span className="tw-text-primary-4">
          watch the sun set in the Gobi desert
        </span>
        "
      </>
    ),
    thumbnailSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/mongolia_desert_pic.png",
    reviewer: {
      name: "Jason Hee",
      profilePictureSrc:
        "https://d1puf2nnbcyl9q.cloudfront.net/6122fc593b17cf38585ce5f7c9840a24-s",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.29) 15.56%, rgba(0, 0, 0, 0) 23.15%, rgba(0, 0, 0, 0) 69.8%, rgba(0, 0, 0, 0.58) 74.58%)",
  },
  {
    title: "Immerse in the rich culture of Mongolia",
    description: (
      <>
        "I can't believe I tried{" "}
        <span className="tw-text-primary-4">horse & camel milk</span>. This trip
        was such an eye-opening experience. .”
      </>
    ),
    thumbnailSrc:
      "https://d1puf2nnbcyl9q.cloudfront.net/mongolia_eagle_pic.png",
    reviewer: {
      name: "Tan",
      profilePictureSrc:
        "https://d1puf2nnbcyl9q.cloudfront.net/1117d10d45e1e185871d5f51c42903c7-m",
    },
    gradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.312) 18.89%, rgba(0, 0, 0, 0) 33.94%, rgba(0, 0, 0, 0) 53.36%, rgba(0, 0, 0, 0.468) 75.46%)",
  },
];

import unauthAxios from "../axios";

export const fullSearchTrips = (search, userId) =>
  unauthAxios
    .get("/v1/search/trips", {
      params: { search, userId },
    })
    .then((res) => res.data.data);

export const fullSearchProjects = (search, userId) =>
  unauthAxios
    .get("/v1/search/projects", {
      params: { search, userId },
    })
    .then((res) => res.data.data);

import React from "react";
import { useGlobalState } from "../../../GlobalStates";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Button from "@mui/material/Button";
import MenuNavItem from "./MenuNavItem";
import { useNavigate, useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TravelIcon from "@mui/icons-material/TravelExplore";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { isMobile } from "react-device-detect";
import { isEmptyObject } from "../../../utils/utils";
import "./index.scss";
import { ImageResizable } from "../../elements/ImageResizeable";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

export const MenuButton = ({
  handleClick,
  handleClose,
  anchorEl,
  handleProfileClick,
  open,
}) => {
  const [user, setUser] = useGlobalState("user");
  const [_, setOpenAlertBannerMsg] = useGlobalState("openAlertBannerMsg");
  const location = useLocation();
  const isInApp = window.isInApp;

  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("USER_ID");
    localStorage.removeItem("JWT_TOKEN");
    setUser({});
  };

  const showMenuItems = () => {
    if (isInApp) {
      return [
        <MenuNavItem
          icon={<NotificationsNoneOutlinedIcon fontSize="small" />}
          key="Notifications"
          label="Notifications"
          onClick={() => {
            handleClose();
            navigate("/notif-edit");
          }}
        />,
        <MenuNavItem
          icon={<LogoutIcon fontSize="small" />}
          key="Log out"
          label="Log out"
          onClick={() => {
            logout();
            window.postMessage("logout");
            navigate("/all-trips");
          }}
        />,
      ];
    } else {
      return [
        <MenuNavItem
          className="profile-item"
          icon={<AccountCircleIcon fontSize="small" />}
          key="Profile"
          label="Profile"
          onClick={handleProfileClick}
        />,
        <MenuNavItem
          icon={<TravelIcon fontSize="small" />}
          key="My trips"
          label="My trips"
          onClick={() => {
            handleClose();
            if (window.isInApp) window.postMessage("Bookings");
            else navigate("/my-trips");
            handleClose();
          }}
        />,

        <CopyToClipboard
          text={`Use this link to get up to $40 credits back on your first Sotravel trip! 👉 www.sotravel.com/welcome-discount?code=${user.id}`}
        >
          <MenuNavItem
            icon={<HandshakeOutlinedIcon fontSize="small" />}
            key="Refer"
            label="Refer & earn"
            onClick={() => {
              setOpenAlertBannerMsg("Referral link copied");
              handleClose();
            }}
          />
        </CopyToClipboard>,
        <MenuNavItem
          icon={<MonetizationOnIcon fontSize="small" />}
          key="Socredits"
          label={`Socredits: $${user.credits || 0}`}
        />,
        <MenuNavItem
          icon={<NotificationsNoneOutlinedIcon fontSize="small" />}
          key="Notifications"
          label="Notifications"
          onClick={() => {
            handleClose();
            navigate("/notif-edit");
          }}
        />,
        <MenuNavItem
          icon={<LogoutIcon fontSize="small" />}
          key="Log out"
          label="Log out"
          onClick={() => {
            logout();
            window.postMessage("logout");
            navigate("/all-trips");
          }}
        />,
      ];
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Button
          variant={isMobile ? "" : "outlined"}
          style={
            isMobile
              ? { padding: 0, margin: 0 }
              : {
                  borderRadius: "20px",
                  borderColor: "lightgrey",
                  width: "4.8rem",
                  height: "2.8rem",
                  paddingRight: 5,
                  paddingLeft: 5,
                }
          }
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {!isMobile && (
            <MenuIcon style={{ color: "grey", fontSize: "1.3rem" }} />
          )}

          {isMobile && location.pathname === "/profile" ? (
            <MenuOutlinedIcon style={{ color: "black", fontSize: "2rem" }} />
          ) : (
            <div
              style={
                isMobile
                  ? {
                      height: "2.7rem",
                      width: "2.7rem",
                      backgroundColor: "lightgrey",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginLeft: 5,
                    }
                  : {
                      height: "2rem",
                      width: "2rem",
                      backgroundColor: "lightgrey",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginLeft: 5,
                    }
              }
            >
              {user && user.image ? (
                <ImageResizable
                  style={
                    isMobile
                      ? {
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          border: "1px solid lightgrey",
                          borderRadius: "50%",
                        }
                      : {
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }
                  }
                  alt={user.name}
                  src={user.image}
                  size="s"
                />
              ) : (
                <Avatar
                  sx={
                    isMobile
                      ? { width: 44, height: 44 }
                      : { width: 33, height: 33 }
                  }
                ></Avatar>
              )}
            </div>
          )}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isEmptyObject(user) ? (
          <MenuNavItem
            className="login-item"
            label="Login"
            onClick={() => {
              navigate({
                pathname: "/login",
                search: `?reroute=/`,
              });
            }}
          />
        ) : (
          showMenuItems()
        )}
      </Menu>
    </React.Fragment>
  );
};

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Dialog from "@mui/material/Dialog";
import { Stack } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { DateWrapper } from "./DateWrapper";
import "./index.scss";
import { LocationWrapper } from "./LocationWrapper";
import SearchWrapper from "./SearchWrapper";
import { createPortal } from "react-dom";
import { SearchIcon } from "../header";

export const FilterMobile = ({
  selectedDates,
  handleSelectDates,
  selectedRegions,
  handleSelectRegions,
  searchMobile,
  handleCloseSearchMobile,
  handleSelectMonths,
  handleSearch,
  handleClearSelections,
  dates,
  search,
  onChangeSearch,
}) => {
  const [currentFocus, setCurrentFocus] = useState("dates"); // dates or regions
  const handleChangeFocus = (focus) => {
    setCurrentFocus(focus);
  };

  const searchRef = useRef(null);

  return (
    <Dialog
      open={searchMobile}
      onClose={handleCloseSearchMobile}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "#F7F7F7",
        },
      }}
    >
      <div
        style={{
          backgroundColor: "F7F7F7",
          display: "flex",
          position: "fixed",
          top: 0,
          width: "100%",
          height: "3.5rem",
          opacity: 0.99,
          zIndex: 100,
        }}
      >
        <button
          style={{
            height: "2.5rem",
            width: "2.5rem",
            border: "1px solid darkgrey",
            borderRadius: "50%",
            margin: "1rem",
            marginTop: "1.5rem",
          }}
          onClick={handleCloseSearchMobile}
        >
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>x</p>
        </button>
      </div>
      <div className="MobileDateLocationChoiceWrapper">
        <div
          className="MobileDateLocationChoiceContainer"
          onClick={() => {
            handleChangeFocus("search");
            setTimeout(() => {
              searchRef.current?.focus();
            }, 100);
          }}
        >
          <div className="DateSummaryContainer">
            {!search ? (
              <p style={{ color: "grey" }}>Search</p>
            ) : (
              <p>{search}</p>
            )}
            <SearchIcon
              style={{
                position: "absolute",
                right: "16px",
                width: "40px",
                height: "40px",
              }}
            />
          </div>

          {createPortal(
            <AnimatePresence>
              {currentFocus === "search" && (
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                  transition={{
                    duration: 0.2,
                    ease: "easeInOut",
                  }}
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "calc(100vh)",
                    backgroundColor: "white",
                    zIndex: 10000,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    transformOrigin: "center",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0, duration: 0.1 }}
                  >
                    <SearchWrapper
                      onOutsideClick={() => {
                        setCurrentFocus(null);
                      }}
                      onChangeSearch={onChangeSearch}
                      onSearch={() => {
                        handleSearch();
                        handleCloseSearchMobile();
                      }}
                      searchQuery={search}
                      searchRef={searchRef}
                    />
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>,
            document.body
          )}
        </div>

        <div
          className="MobileDateLocationChoiceContainer"
          onClick={() => handleChangeFocus("regions")}
        >
          {currentFocus === "regions" ? (
            <LocationWrapper
              selectedRegions={selectedRegions}
              handleSelectRegions={handleSelectRegions}
            />
          ) : (
            <div className="DateSummaryContainer">
              <p style={{ color: "grey" }}>Where</p>
              {selectedRegions.length === 1 ? (
                <p>{selectedRegions[0]}</p>
              ) : selectedRegions.length > 1 ? (
                <p>{selectedRegions[0]} & more</p>
              ) : (
                <p></p>
              )}
            </div>
          )}
        </div>

        <div
          className="MobileDateLocationChoiceContainer"
          onClick={() => handleChangeFocus("dates")}
        >
          {currentFocus === "dates" ? (
            <DateWrapper
              selectedDates={selectedDates}
              handleSelectDates={handleSelectDates}
              handleSelectMonths={handleSelectMonths}
              dates={dates}
            />
          ) : (
            <div className="DateSummaryContainer">
              <p style={{ color: "grey" }}>When</p>
              {selectedDates.length === 1 ? (
                <p>{selectedDates[0]}</p>
              ) : selectedDates.length > 1 ? (
                <p>{selectedDates[0]} & more</p>
              ) : (
                <p></p>
              )}
            </div>
          )}
        </div>

        <Stack
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          style={{
            bottom: "0",
            backgroundColor: "white",
            height: "5rem",
            borderTop: "1px solid rgb(226, 226, 226)",
          }}
        >
          {currentFocus !== "search" ? (
            <button onClick={handleClearSelections}>
              <span
                style={{
                  marginLeft: "20px",
                  fontWeight: 900,
                  textDecoration: "underline",
                }}
              >
                Clear all
              </span>
            </button>
          ) : (
            <div />
          )}

          <button
            className="DateLocationSearchButton"
            onClick={() => {
              handleSearch();
              handleCloseSearchMobile();
            }}
            style={{ marginRight: "20px" }}
          >
            <FilterAltOutlinedIcon className="DateLocationSearchButtonIcon" />
            <span style={{ marginLeft: "10px" }}>Filter</span>
          </button>
        </Stack>
      </div>{" "}
    </Dialog>
  );
};

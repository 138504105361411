import React from "react";
import { isMobile } from "react-device-detect";

export const LocationWrapper = ({ selectedRegions, handleSelectRegions }) => {
  const regions = [
    ["I'm flexible", "/map_images/map_world.jpeg"],
    ["Asia", "/map_images/map_sea.webp"],
    ["Europe", "/map_images/map_europe.webp"],
    ["Australia", "/map_images/map_australia.webp"],
    ["Africa", "/map_images/map_africa.webp"],
    ["America", "/map_images/map_south_america.webp"],
  ];

  return (
    <div className="LocationContainer">
      {isMobile ? (
        <p style={{ fontWeight: "800", fontSize: "20px" }}>Where to?</p>
      ) : (
        <p style={{ fontWeight: "800" }}>Filter by region</p>
      )}

      <div className="LocationGridBox">
        {regions.map(([region, image], key) => (
          <div className="LocationBoxWrapper">
            <button
              className={`LocationBox ${
                selectedRegions.includes(region) ? "selected" : ""
              }`}
              onClick={() => handleSelectRegions(region)}
              style={{
                backgroundImage: `url('${image}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></button>
            <p style={{ marginTop: "3px" }}>{region}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

import * as React from "react";

const GoogleReviewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    fill="none"
    viewBox="0 0 20 19"
  >
    <path
      stroke="#fff"
      strokeWidth="0.667"
      d="M1.333 11.167H3v3.666a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V4.167a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v3.889l-1.933 2.577-.4.534z"
    ></path>
    <path
      fill="#fff"
      d="M13.327 4.006c.743.251 1.413.668 1.988 1.198-.136.146-.279.286-.42.427L13.69 6.836a3.2 3.2 0 0 0-1.04-.66 3.4 3.4 0 0 0-3.228.463 3.6 3.6 0 0 0-1.312 1.94 3.5 3.5 0 0 0-.029 1.691 3.6 3.6 0 0 0 1.247 1.983 3.4 3.4 0 0 0 1.878.743c.476.032.963-.02 1.419-.163.477-.152.931-.41 1.275-.78.363-.385.61-.875.701-1.397H11.45V8.399h5.442c.148.839.148 1.7-.018 2.536a5.5 5.5 0 0 1-1.036 2.328 5 5 0 0 1-1.667 1.38c-.807.418-1.721.608-2.626.622a5.796 5.796 0 0 1-4.93-2.614 5.75 5.75 0 0 1-.931-2.745c-.07-.926.09-1.868.46-2.72a5.82 5.82 0 0 1 2.516-2.768 5.83 5.83 0 0 1 4.668-.412"
    ></path>
  </svg>
);

export default GoogleReviewIcon;

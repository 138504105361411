import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, CircularProgress, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Markdown from "markdown-to-jsx";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSearchProjects } from "../../../customHooks/search";
import { ImageResizable } from "../../elements/ImageResizeable";

function SearchWrapper({
  onOutsideClick,
  onChangeSearch,
  searchQuery: _searchQuery,
  onSearch,
  searchRef,
}) {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // Handle the outside click (e.g., close the search)
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Auto focus on the search input when component mounts
    if (searchRef?.current) {
      searchRef.current.focus();
    }
  }, []);

  const { searchResults, isSearching, handleSearchQueryChange, searchQuery } =
    useSearchProjects(_searchQuery, onChangeSearch);

  return (
    <div ref={wrapperRef} style={{ height: "100%" }}>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          background: "white",
          paddingInline: "1.5rem",
          paddingTop: "1.5rem",
          paddingBottom: "8px",
          marginBottom: "1rem",
        }}
      >
        <TextField
          inputRef={searchRef}
          fullWidth
          placeholder="Search trips & activities"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              border: "none",
              padding: 0,
              paddingLeft: "2.5rem", // Add padding for the back button
            },
            input: {
              cursor: "default",
              "&:not(:focus)": {
                cursor: "pointer",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onOutsideClick();
                }}
                style={{
                  position: "absolute",
                  left: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ArrowBackIcon />
              </button>
            ),
            endAdornment: (
              <div
                style={{
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "deepskyblue",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => {
                  onSearch();
                  onOutsideClick();
                }}
              >
                <SearchOutlinedIcon
                  style={{
                    color: "white",
                  }}
                />
              </div>
            ),
          }}
        />
      </div>

      {isSearching ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        !!searchResults.length && (
          <div>
            {searchResults.map((result) => (
              <TripPreview trip={result} key={result.id} />
            ))}
          </div>
        )
      )}
    </div>
  );
}

export default SearchWrapper;

function TripPreview({ trip: result }) {
  return (
    <Link
      key={result.id}
      to={`/trips/${result.id}`} // Updated to use slug for the URL
      style={{
        textDecoration: "none",
        paddingInline: "1.5rem",
        display: "block",
      }}
      data-search-results
    >
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          transition: "background-color 0.3s",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#f0f0f0", // Light gray background on hover
          },
        }}
      >
        <div
          style={{
            width: "20%",
            height: "60px",
          }}
        >
          <ImageResizable
            src={result.thumbnail_url} // Updated to use the first image from main_images
            alt={result.name} // Updated alt text to project_name
            style={{
              width: "60px", // Adjusted width for better list layout
              height: "60px", // Adjusted height for better list layout
              objectFit: "cover", // Ensure the image covers the area
              borderRadius: "8px", // Slightly smaller border radius
              marginRight: "16px", // Space between image and text
              flex: "none",
            }}
            size="s"
          />
        </div>

        <div style={{ textAlign: "left", width: "80%" }}>
          <p
            style={{
              margin: 0,
              fontWeight: "600",
              fontSize: "16px",
              color: "#222",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {result.name}
          </p>
          <Markdown
            style={{
              margin: 0,
              color: "grey",
              fontSize: "12px",
              lineHeight: "1.4",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              lineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {result.summary}
          </Markdown>
        </div>
      </div>
    </Link>
  );
}

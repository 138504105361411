import { useState, useEffect } from "react"; // Added useCallback
import { useGlobalState } from "../GlobalStates";
import { fullSearchProjects } from "../services/search";

export const useSearchProjects = (defaultSearchQuery, onChangeSearch) => {
  const [user] = useGlobalState("user");
  const [userLoading] = useGlobalState("userLoading");

  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [isSearching, setIsSearching] = useState(!!defaultSearchQuery);
  const [searchResults, setSearchResults] = useState([]);
  const [debouncedSearchQuery, setDebouncedSearchQuery] =
    useState(defaultSearchQuery);

  useEffect(() => {
    (async () => {
      if (!userLoading) {
        setSearchQuery(defaultSearchQuery);
        setDebouncedSearchQuery(defaultSearchQuery);

        if (defaultSearchQuery) {
          setIsSearching(true);
          const projects = await fullSearchProjects(
            defaultSearchQuery,
            user?.id
          );

          setSearchResults(projects);

          setIsSearching(false);
        }
      }
    })();
  }, [user?.id, userLoading]);

  const handleSearchQueryChange = (e) => {
    if (e.target.value === "") {
      setSearchQuery("");
      setIsSearching(false);
      setSearchResults([]);
      setDebouncedSearchQuery("");
      onChangeSearch?.("");
      return;
    }

    setSearchQuery(e.target.value);
    setIsSearching(true);

    // Debounce logic
    clearTimeout(window.debounceTimeout);
    window.debounceTimeout = setTimeout(async () => {
      setDebouncedSearchQuery(e.target.value);
      onChangeSearch?.(e.target.value);

      const projects = await fullSearchProjects(e.target.value, user?.id);

      setSearchResults(projects);

      setIsSearching(false);
    }, 300); // Debounce for 300ms
  };

  return {
    searchResults,
    isSearching,
    searchQuery,
    handleSearchQueryChange,
    debouncedSearchQuery,
    setSearchQuery,
    setDebouncedSearchQuery,
  };
};

import * as React from "react";

const StarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    fill="none"
    viewBox="0 0 16 15"
  >
    <path
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="m8.333.833 2.06 4.174L15 5.68l-3.334 3.247.787 4.586-4.12-2.166-4.12 2.166L5 8.927 1.667 5.68l4.606-.673z"
    ></path>
  </svg>
);

export default StarIcon;

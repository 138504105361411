import React, { useEffect, useState } from "react";
import "./Home.scss";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/system";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { useGlobalState } from "../GlobalStates";
import { ImageResizable } from "../components/elements/ImageResizeable.js";

let website_url = "https://www.sotravel.com";
if (process.env.NODE_ENV === "development") {
  website_url = "http://localhost:3000";
}

export const ProjectBox = ({
  id,
  trip_id,
  project_id,
  name,
  main_images,
  max_slots,
  price,
  skill_level,
  duration,
  country,
  city,
  highlights,
  three_user_images,
  main_video,
  boxStyle,
  containerStyle,
}) => {
  const navigate = useNavigate();
  const [promotion, setPromotion] = useGlobalState("promotion");

  return (
    <div className="HomeTripContainer" style={containerStyle}>
      <button
        className="HomeTripBox"
        onClick={() => {
          if (isMobile) {
            navigate(`/trips/${project_id}`, {
              state: {
                main_images: main_images,
                main_video: main_video,
                name: name,
                country: country,
                city: city,
                duration: duration,
                skill_level: skill_level,
              },
            });
          } else {
            window.open(`${website_url}/trips/${project_id}`, "_blank");
          }
        }}
        style={boxStyle}
      >
        <Carousel
          className="HomeImageCarousel"
          navButtonsAlwaysInvisible={isMobile}
          autoPlay={false}
          animation="slide"
          duration={300}
          indicators={true}
          indicatorIconButtonProps={{
            style: {
              padding: "1px",
              color: "lightgray",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "white",
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "-30px",
              position: "absolute",
              zIndex: "10",
            },
          }}
          style={{ zIndex: 1 }}
        >
          {main_images &&
            main_images.map((imageUrl, index) => (
              <ImageResizable
                key={index}
                src={imageUrl}
                className="HomeImage"
                fadeIn={true}
                size={isMobile ? "l" : "xl"}
              />
            ))}
        </Carousel>

        <div className="HomeTripSummary">
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            width="100%"
          >
            <span
              style={{
                color: "#222222",
                fontWeight: "600",
                width: "100%",
                textAlign: "left",
              }}
            >
              {name}
            </span>
          </Stack>

          <Stack
            direction="row"
            spacing={0.6}
            alignItems="center"
            style={{ marginTop: "2px", marginBottom: "1px" }}
          >
            <span
              style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}
            >
              {country}
            </span>
            <FiberManualRecordIcon
              style={{ fontSize: "3px", color: "#333333" }}
            />
            <span
              style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}
            >
              {duration}
            </span>
            <FiberManualRecordIcon
              style={{ fontSize: "3px", color: "#333333" }}
            />
            <span
              style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}
            >
              {skill_level}
            </span>
          </Stack>

          <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
            marginTop="5px"
          >
            {highlights &&
              highlights.map((highlight, i) => (
                <div className="ProjectBoxHighlights" key={highlight.key}>
                  <div className="ProjectBoxHighlightsRow">
                    <div className="ProjectBoxHighlightsRowLeft">
                      <StarIcon style={{ fontSize: "10px", margin: "4px" }} />
                    </div>
                    <div className="ProjectBoxHighlightsRowRight">
                      <p className="ProjectBoxHighlightsRowRightDesc">
                        {highlight.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </Stack>
        </div>

        <div
          className="HomeTripDetailsGroupButton"
          style={{ marginTop: "10px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <div
              className="HomeTripDetailsGroup"
              // onClick={handleOpenGroupDetails}
            >
              <div style={{ paddingTop: "1.3rem" }}></div>
              {three_user_images &&
                three_user_images.map((imageUrl, index) => (
                  <ImageResizable
                    size="xs"
                    key={index}
                    className="HomeTripDetailsGroupMembersProfile"
                    src={imageUrl}
                    style={
                      index === 1
                        ? { marginLeft: "1rem", animationDelay: "0.3s" }
                        : index === 2
                        ? {
                            marginLeft: "2rem",
                            animationDelay: "0.6s",
                          }
                        : {}
                    }
                  />
                ))}

              <span
                className="HomeTripDetailsGroupSlotsSpan"
                style={
                  three_user_images.length > 0
                    ? { fontWeight: 600, color: "black" }
                    : {
                        fontSize: "15px",
                        fontWeight: 600,
                        color: "black",
                      }
                }
              >
                From ${price}
              </span>
            </div>

            <Button
              className="hometrip-waitlist-button"
              // onClick={(e) => {
              //   handleShowTripDetails(e);
              // }}
            >
              <>
                <span>See all dates</span>
              </>
            </Button>
          </Stack>
        </div>
      </button>
    </div>
  );
};

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  adventurerReviews,
  adventures,
  whyChooseUs,
} from "../constants/landing-new";
import {
  FAQs,
  Footer,
  HeroFeatured,
  StickyHeader,
  WhyChooseUs,
} from "./components/landing-new";
import { useIsDesktop, useTabletOrMobile } from "../hooks/responsive";
import { socket } from "../config/socket";

const LandingNew = () => {
  return (
    <>
      <StickyHeader />
      <HeroFeatured
        desktopVideo="https://d1puf2nnbcyl9q.cloudfront.net/desktop_landing.mp4"
        mobileVideo="https://d1puf2nnbcyl9q.cloudfront.net/mobile_landing.mov"
        mainText="Where adventure meets community"
        subText="Pack your bags and leave the rest to us"
        ctaText="Find your next adventure"
        ctaLink="/all-trips"
      />
      <MeetFeatured />
      <WhyChooseUs
        title="Why people choose us?"
        description="Right from our customer's lips 🫦"
        reasons={whyChooseUs}
      />
      <CheckoutFeatured />
      <FAQs />
      <Footer />
    </>
  );
};

const MeetFeatured = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [desktopPage, setDesktopPage] = useState(0);

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    setDirection(newDirection);
    if (newDirection < 0) {
      // Changed direction check
      if (currentIndex === 0) {
        setCurrentIndex(adventurerReviews.length - 1);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
    } else {
      if (currentIndex === adventurerReviews.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }
  };

  const nextDesktopPage = () => {
    setDesktopPage((prev) => {
      if ((prev + 1) * 3 >= adventurerReviews.length) {
        return 0;
      }
      return prev + 1;
    });
  };

  const variants = {
    enter: (direction) => ({
      x: direction < 0 ? 1000 : -1000, // Changed direction check
      opacity: 0,
      rotate: direction < 0 ? 10 : -10, // Changed direction check
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      rotate: 0,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction > 0 ? 1000 : -1000, // Changed direction check
      opacity: 0,
      rotate: direction > 0 ? 10 : -10, // Changed direction check
    }),
  };

  const isMobile = useTabletOrMobile();

  const ReviewCard = ({ review }) => (
    <div className="tw-relative tw-rounded-3xl tw-overflow-hidden">
      <div className="tw-relative tw-w-full">
        <img
          className="tw-w-full tw-h-[70vh] md:tw-h-[400px] tw-object-cover tw-pointer-events-none"
          src={review.thumbnailSrc}
          alt="Adventurer"
        />
        <div
          className="tw-absolute tw-inset-0"
          style={{
            background:
              "linear-gradient(180.04deg, rgba(0, 39, 51, 0.64) 14.53%, rgba(0, 39, 51, 0) 39.51%, rgba(0, 39, 51, 0.64) 76.22%)",
          }}
        ></div>
      </div>

      <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-flex tw-flex-col tw-px-4 tw-pt-4 tw-rounded-3xl">
        <div className="tw-text-center tw-text-white tw-text-[10px] tw-mb-4 tw-flex tw-flex-col tw-gap-0.5 tw-font-bold">
          Review for{" "}
          <span className="tw-font-bold tw-text-2xl">{review.reviewTo}</span>
        </div>
      </div>

      <div className="tw-absolute tw-bottom-4 tw-left-4 tw-right-4 tw-pt-4 tw-rounded-3xl tw-flex tw-items-end tw-gap-2">
        <div className="tw-grow">
          <div className="tw-text-white tw-mb-1 tw-text-base">
            {review.review}
          </div>
          <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
            <img
              className="tw-rounded-full tw-w-6 tw-h-6 tw-object-cover"
              src={review.reviewFromProfilePictureSrc}
              alt="Reviewer"
            />
            <div>
              <div className="tw-text-[10px] tw-text-white">
                <span className="tw-font-bold">{review.reviewFrom}</span>
              </div>
              <div className="tw-text-white tw-text-[10px]">
                {review.reviewFrom} & {review.reviewTo} met in{" "}
                <span className="tw-font-bold">{review.tripName}</span>
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={() => paginate(-1)}
            className="tw-bg-white/80 tw-backdrop-blur-sm tw-rounded-full tw-p-3 md:tw-p-3 tw-shadow-lg hover:tw-bg-white/90 tw-transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-h-6 tw-w-6 md:tw-h-6 md:tw-w-6 tw-text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          </motion.button>
        )}
      </div>
    </div>
  );

  return (
    <div className="tw-bg-[#EBFAFF] tw-px-4 md:tw-px-16 lg:tw-px-24 tw-pt-[calc(40px+55px)] tw-pb-[40px] md:tw-pt-[calc(64px+55px)] md:tw-pb-16 tw-overflow-hidden">
      <div className="tw-text-[26px] md:tw-text-[42px] tw-text-center tw-font-extrabold tw-mb-1">
        What our participants say about each other
      </div>
      <div className="tw-text-base md:tw-text-xl tw-text-center tw-text-primary-main tw-font-semibold tw-mb-8 md:tw-mb-12">
        We kept it PG13 👀
      </div>

      <div className="tw-max-w-[1200px] tw-mx-auto">
        {/* Mobile View */}
        <div className="md:tw-hidden tw-relative tw-h-[70vh] tw-mb-8">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
                rotate: { duration: 0.5 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1); // Changed direction
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1); // Changed direction
                }
              }}
              className="tw-absolute tw-w-full tw-cursor-grab"
            >
              <ReviewCard review={adventurerReviews[currentIndex]} />
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Desktop View */}
        <div className="tw-hidden md:tw-block">
          <div className="tw-grid md:tw-grid-cols-3 tw-gap-6 tw-relative">
            <AnimatePresence initial={false} mode="wait">
              {adventurerReviews
                .slice(desktopPage * 3, desktopPage * 3 + 3)
                .map((review, index) => (
                  <motion.div
                    key={`${desktopPage}-${index}`}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{
                      duration: 0.4,
                      delay: index * 0.15,
                      ease: "easeOut",
                    }}
                    className="tw-will-change-transform"
                  >
                    <ReviewCard review={review} />
                  </motion.div>
                ))}
            </AnimatePresence>
            <div className="tw-absolute tw-right-[-60px] tw-top-1/2 tw-transform tw--translate-y-1/2">
              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={nextDesktopPage}
                className="tw-bg-white/80 tw-backdrop-blur-sm tw-rounded-full tw-p-3 tw-shadow-lg hover:tw-bg-white/90 tw-transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-6 tw-w-6 tw-text-green-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
              </motion.button>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-justify-center">
        <Link to="https://t.me/sotravel_sg" target="_blank">
          <button className="tw-bg-primary-main tw-text-white tw-rounded-2xl tw-px-16 tw-py-3 tw-text-base tw-font-bold tw-w-full mt-8 md:tw-mt-12 hover:tw-bg-primary-dark tw-transition-colors">
            Join Sotravel Community
          </button>
        </Link>
      </div>
    </div>
  );
};

const CheckoutFeatured = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoRefs] = useState(adventures.map(() => React.createRef()));
  const scrollContainerRef = useRef(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [playingState, setPlayingState] = useState(adventures.map(() => false));
  const [hasInteracted, setHasInteracted] = useState(false);

  const isMobile = useTabletOrMobile();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container || !hasInteracted || isDesktop) return; // Skip for desktop

    let isScrolling = false;
    let scrollTimeout;

    const handleScroll = () => {
      if (!isScrolling) {
        isScrolling = true;
      }

      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        isScrolling = false;

        const containerHeight = container.clientHeight;
        const scrollTop = container.scrollTop;
        const newIndex = Math.round(scrollTop / containerHeight);

        if (newIndex !== currentVideoIndex) {
          if (videoRefs[currentVideoIndex].current) {
            videoRefs[currentVideoIndex].current.pause();
            setPlayingState((prev) => {
              const newState = [...prev];
              newState[currentVideoIndex] = false;
              return newState;
            });
          }

          if (videoRefs[newIndex].current) {
            videoRefs[newIndex].current.play();
            setPlayingState((prev) => {
              const newState = [...prev];
              newState[newIndex] = true;
              return newState;
            });
            setIsPlaying(true);
          }

          setCurrentVideoIndex(newIndex);
        }
      }, 0);
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [currentVideoIndex, videoRefs, hasInteracted, isDesktop]);

  return (
    <div
      className={`tw-px-4 md:tw-px-16 lg:tw-px-24 tw-py-10 md:tw-py-10 tw-overflow-hidden ${
        isPlaying ? "tw-bg-black" : "tw-bg-white"
      }`}
    >
      <div
        className={`tw-text-[26px] md:tw-text-[42px] tw-font-extrabold tw-text-center tw-mb-8 md:tw-mb-12 tw-max-w-[300px] md:tw-max-w-[600px] tw-mx-auto ${
          isPlaying ? "tw-text-white" : "tw-text-black"
        }`}
      >
        Watch our adventures on Tiktok
      </div>

      {isDesktop && (
        <>
          {/* Desktop Grid Layout */}
          <div className="tw-flex tw-justify-center">
            <div className="tw-hidden md:tw-grid md:tw-grid-cols-3 tw-gap-8 tw-mb-12 tw-mx-auto">
              {adventures.slice(0, 3).map((adventure, index) => (
                <CheckoutAdventureVideo
                  key={index}
                  adventure={adventure}
                  onChangePlaying={(is) => {
                    setIsPlaying(is);
                    setHasInteracted(true);
                  }}
                  playingState={playingState}
                  onChangePlayingState={(index, isPlaying) =>
                    setPlayingState((prev) => {
                      const newState = [...prev];
                      newState[index] = isPlaying;
                      return newState;
                    })
                  }
                  videoRef={videoRefs[index]}
                  videoRefs={videoRefs}
                  index={index}
                  isDesktop={true}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {isMobile && (
        <>
          {/* Mobile Scroll Layout */}
          <div className="md:tw-hidden">
            <div
              ref={scrollContainerRef}
              className={`tw-relative tw-rounded-[24px] tw-overflow-auto tw-mb-8 tw-h-[70vh] [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none] tw-snap-y tw-snap-mandatory ${
                isPlaying ? "tw-bg-black" : ""
              }`}
            >
              {adventures.map((adventure, index) => (
                <CheckoutAdventureVideo
                  key={index}
                  adventure={adventure}
                  onChangePlaying={(is) => {
                    setIsPlaying(is);
                    setHasInteracted(true);
                  }}
                  playingState={playingState}
                  onChangePlayingState={(index, isPlaying) =>
                    setPlayingState((prev) => {
                      const newState = [...prev];
                      newState[index] = isPlaying;
                      return newState;
                    })
                  }
                  videoRef={videoRefs[index]}
                  videoRefs={videoRefs}
                  index={index}
                  isDesktop={false}
                />
              ))}
            </div>
          </div>
        </>
      )}

      <div className="md:tw-flex md:tw-justify-center">
        <a
          href="https://www.tiktok.com/@sotravel_sg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className={`tw-bg-black tw-text-white tw-rounded-[10px] tw-px-16 tw-py-3 tw-text-base md:tw-text-lg tw-font-bold tw-w-full md:tw-mx-auto md:tw-block ${
              isPlaying ? "tw-opacity-80" : ""
            }`}
          >
            Open TikTok
          </button>
        </a>
      </div>
    </div>
  );
};

function CheckoutAdventureVideo({
  adventure,
  onChangePlaying,
  videoRef,
  videoRefs,
  index,
  playingState,
  onChangePlayingState,
  isDesktop,
}) {
  const isPlaying = playingState[index];
  const [showScrollHint, setShowScrollHint] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        onChangePlayingState(index, true);
        // Only pause other videos in mobile view
        if (!isDesktop) {
          for (const [idx, ref] of videoRefs.entries()) {
            if (idx !== index) {
              ref.current.pause();
              onChangePlayingState(idx, false);
            }
          }
        }
        onChangePlaying(true);
        if (!showScrollHint && !isDesktop) {
          setShowScrollHint(true);
          setTimeout(() => setShowScrollHint(false), 3000);
        }
      } else {
        videoRef.current.pause();
        onChangePlayingState(index, false);
        onChangePlaying(false);
      }
    }
  };

  useEffect(() => {
    videoRef.current.load();
  }, []);

  const videoClasses = isDesktop
    ? "tw-h-[480px] tw-aspect-[9/12] tw-mx-auto tw-object-cover tw-rounded-2xl"
    : "tw-h-[70vh] tw-w-full tw-object-cover";

  const containerClasses = isDesktop
    ? "tw-relative tw-cursor-pointer tw-overflow-hidden tw-rounded-2xl tw-aspect-[9/12] tw-h-[480px]"
    : "tw-relative tw-snap-start tw-snap-always tw-h-[70vh]";

  return (
    <div className={containerClasses} onClick={handleVideoClick}>
      <video
        ref={videoRef}
        src={adventure.url}
        className={videoClasses}
        loop
        playsInline
      />

      {!isPlaying && (
        <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <button className="tw-bg-white/30 tw-rounded-full tw-p-4 tw-backdrop-blur-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        </div>
      )}

      {showScrollHint && adventures.length > 1 && !isDesktop && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
        >
          <div className="tw-text-white tw-text-lg tw-text-center tw-font-medium tw-drop-shadow-lg">
            Swipe up
          </div>
          <div className="tw-flex tw-justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="white"
              className="tw-animate-bounce"
            >
              <path d="M7 14l5-5 5 5z" />
            </svg>
          </div>
        </motion.div>
      )}

      <div className="tw-absolute tw-bottom-4 tw-left-4 tw-right-4 tw-flex tw-flex-col tw-gap-0">
        <div className="tw-text-white tw-text-[18px] md:tw-text-[24px] tw-font-extrabold">
          {adventure.title}
        </div>
        <div className="tw-flex tw-items-center tw-gap-1">
          <div className="tw-text-white tw-text-[12px] md:tw-text-sm tw-grow">
            {adventure.caption}
          </div>
          <a
            href={adventure.link}
            className="tw-bg-[#FFFFFF]/70 tw-text-[#495057] tw-rounded-full tw-px-5 tw-py-2 tw-text-[10px] md:tw-text-sm tw-font-bold tw-flex-none hover:tw-bg-white tw-transition-colors"
          >
            Go to trip
          </a>
        </div>
      </div>
    </div>
  );
}

export default LandingNew;

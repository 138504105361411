import * as React from "react";

const ArrowUpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#00C1FD"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="m7 9 3-3m0 0 3 3m-3-3v8m0-13a9 9 0 1 1 0 18 9 9 0 0 1 0-18"
    ></path>
  </svg>
);

export default ArrowUpIcon;

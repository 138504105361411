import React, { useEffect, useState } from "react";
import { unauthAxiosFetcher } from "../axios";
import { mongoliaWhyChooseUs } from "../constants/destination";
import { ProjectBox } from "../home/ProjectBox";
import {
  FAQs,
  Footer,
  HeroFeatured,
  StickyHeader,
  WhyChooseUs,
} from "./components/landing-new";

// static to Mongolia for now.
// Might be improved and be dynamic in the future
const Destination = () => {
  return (
    <>
      <StickyHeader />
      <HeroFeatured
        desktopVideo="https://d1puf2nnbcyl9q.cloudfront.net/mongolia_landing_vid_desktop.mov"
        mobileVideo="https://d1puf2nnbcyl9q.cloudfront.net/mongolia_landing_vid_mobile.mov"
        ctaText="Browse Mongolia trips"
        onCtaClick={() => {
          document.getElementById("destination-tours").scrollIntoView({
            behavior: "smooth",
          });
        }}
      />
      <WhyChooseUs
        className="tw-mt-12"
        mobileVariant="horizontal"
        title="Why choose Mongolia?"
        description="Hear from those who have visited this destination."
        reasons={mongoliaWhyChooseUs}
        removeCta={true}
      />
      <Tours />
      <FAQs />
      <Footer />
    </>
  );
};

const Tours = () => {
  const [tours, setTours] = useState([]);

  useEffect(() => {
    unauthAxiosFetcher(
      "/v1/project/getProjectsByCountry?country=Mongolia"
    ).then((data) => {
      const formattedTours = data.map((tour) => ({
        id: tour.id,
        project_id: tour.id.toString(),
        name: tour.project_name,
        main_images: tour.main_images,
        price: tour.price,
        skill_level: tour.skill_level || "Beginner",
        duration: tour.duration,
        country: tour.country,
        city: tour.city,
        highlights: tour.highlights.map((highlight) => ({
          key: highlight.highlight_key,
          description: highlight.description,
        })),
        three_user_images: [
          "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61",
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e",
        ],
      }));

      setTours(formattedTours);
    });
  }, []);

  return (
    <div
      className="tw-px-4 md:tw-px-16 tw-py-4 md:tw-py-16"
      id="destination-tours"
    >
      <div className="tw-text-[26px] md:tw-text-[32px] tw-font-extrabold tw-mb-4">
        Mongolia tours
      </div>
      <div className="tw-grid md:tw-grid-cols-2 tw-gap-6 HomeContainer">
        {tours.map((tour) => (
          <ProjectBox
            key={tour.id}
            {...tour}
            boxStyle={{
              margin: "0",
              alignItems: "flex-start",
            }}
            containerStyle={{
              alignItems: "flex-start",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Destination;

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { Stack } from "@mui/system";
import React, { useState, useRef } from "react"; // Added useCallback
import { DateWrapper } from "./DateWrapper";
import "./index.scss";
import { LocationWrapper } from "./LocationWrapper";
import { TextField, Box, Typography, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import { motion } from "framer-motion";
import { useSearchProjects } from "../../../customHooks/search";
import { ImageResizable } from "../../elements/ImageResizeable";

export const Filter = ({
  selectedDates,
  handleSelectDates,
  selectedRegions,
  handleSelectRegions,
  handleSelectMonths,
  handleSearch,
  closePopUp,
  handleClearSelections,
  dates,
  searchQuery: _searchQuery,
  onChangeSearch: _onChangeSearch,
}) => {
  const {
    searchResults,
    isSearching,
    searchQuery,
    handleSearchQueryChange,
    setSearchQuery,
    setDebouncedSearchQuery,
  } = useSearchProjects(_searchQuery, _onChangeSearch);

  const [inputFocused, setInputFocused] = useState(false); // New state for input focus

  const searchInputRef = useRef(null); // Create a ref for the search input

  return (
    <div className="DateLocationChoiceWrapper">
      <SearchInput
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        searchInputRef={searchInputRef}
        inputFocused={inputFocused}
        setInputFocused={setInputFocused}
        closePopUp={closePopUp}
        handleSearch={handleSearch}
        isSearching={isSearching}
      />
      {inputFocused ? (
        <SearchResults
          searchResults={searchResults}
          isSearching={isSearching}
          searchQuery={searchQuery}
        />
      ) : (
        <div className="DateLocationChoiceContainer">
          <DateWrapper
            selectedDates={selectedDates}
            handleSelectDates={handleSelectDates}
            handleSelectMonths={handleSelectMonths}
            dates={dates}
          />
          <div className="Line"></div>
          <LocationWrapper
            selectedRegions={selectedRegions}
            handleSelectRegions={handleSelectRegions}
          />
        </div>
      )}

      {!inputFocused && ( // Adjusted to check input focus state
        <Stack
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          paddingInline="30px"
          paddingBottom="30px"
        >
          {selectedDates.length > 0 ||
          selectedRegions.length > 0 ||
          searchQuery ? (
            <button
              onClick={() => {
                handleClearSelections();
                setSearchQuery("");
                setDebouncedSearchQuery("");
              }}
            >
              <span
                style={{
                  marginLeft: "5px",
                  fontWeight: 900,
                  textDecoration: "underline",
                }}
              >
                Clear all
              </span>
            </button>
          ) : (
            <div></div>
          )}

          <button
            className="DateLocationSearchButton"
            onClick={() => {
              closePopUp();
              handleSearch();
            }}
            style={{ marginTop: "1.2rem" }}
            disabled={isSearching}
          >
            <FilterAltOutlinedIcon className="DateLocationSearchButtonIcon" />
            <span style={{ marginLeft: "10px" }}>Filter</span>
          </button>
        </Stack>
      )}
    </div>
  );
};

const SearchInput = ({
  searchQuery,
  handleSearchQueryChange,
  searchInputRef,
  inputFocused,
  setInputFocused,
  closePopUp,
  handleSearch,
  debouncedSearchQuery,
  isSearching,
}) => {
  return (
    <div
      style={{
        paddingTop: "30px",
        paddingInline: "30px",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: "white",
      }}
    >
      <Box
        tabIndex={0}
        id="searchInputBox"
        onClick={() => searchInputRef.current.focus()}
        onBlur={(e) => {
          if (
            e.relatedTarget
              ?.closest(`[data-search-results-container]`)
              ?.getAttribute("data-search-results-container")
          ) {
            return;
          }

          if (
            e.relatedTarget?.closest(`#searchInputBox`)?.id === "searchInputBox"
          ) {
            searchInputRef.current.focus();
            return;
          }

          setInputFocused(false);
        }} // Update focus state on blur
        onFocus={() => setInputFocused(true)} // Update focus state on focus
        sx={{
          mb: 2,
          borderRadius: "16px",
          border: "1px solid rgba(0, 0, 0, 0.23)", // Maintain the original border style
          display: "flex",
          alignItems: "center",
          paddingBlock: "10px", // Add padding for better click area
          paddingInline: "12px",
          cursor: "pointer", // Change cursor to pointer to indicate it's clickable
          "&:focus-within": {
            border: "1px solid black", // Use black border when focused
          },
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <motion.div
          initial={{ width: "2rem" }}
          animate={inputFocused ? { width: "6rem" } : { width: "2rem" }}
          transition={{ type: "tween" }}
          style={{
            height: "2rem",
            background: "deepskyblue",
            borderRadius: "16px",
            position: "absolute",
            right: "8px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            paddingInline: "6px",
            cursor: "pointer",
            zIndex: 1000,
          }}
          onClick={(e) => {
            e.preventDefault();
            closePopUp();
            handleSearch();
          }}
        >
          <SearchOutlinedIcon
            style={{
              color: "white",
              width: "1.2rem",
              height: "1.2rem",
            }}
          />
          {inputFocused && (
            <span
              style={{ color: "white", marginLeft: "8px", fontWeight: 600 }}
            >
              Search
            </span>
          )}
        </motion.div>
        <TextField
          fullWidth
          placeholder="Search trips, destinations, activities..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isSearching) {
              e.preventDefault();
              closePopUp();
              handleSearch(debouncedSearchQuery);
            }
          }}
          inputRef={searchInputRef}
          sx={{
            mb: 0,
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              border: "none",
              padding: 0,
            },
            input: {
              cursor: "default",
              "&:not(:focus)": {
                cursor: "pointer",
              },
            },
          }}
          InputProps={{
            disableUnderline: true,
          }}
          variant="standard"
        />
      </Box>
    </div>
  );
};

const SearchResults = ({ searchResults, isSearching, searchQuery }) => {
  return (
    <Box sx={{ mb: 2, paddingInline: "30px" }} data-search-results-container>
      {isSearching ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <CircularProgress />
        </Box>
      ) : searchResults.length > 0 ? (
        searchResults.map((result) => (
          <TripPreview trip={result} key={result.id} />
        ))
      ) : (
        <Box sx={{ paddingBottom: 2, paddingInline: 3 }}>
          {searchQuery && (
            <Typography color="text.secondary" align="center">
              No results found
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const TripPreview = ({ trip: result }) => {
  return (
    <Link
      key={result.id}
      to={`/trips/${result.id}`} // Updated to use slug for the URL
      style={{ textDecoration: "none" }}
      data-search-results
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          p: 1, // Added padding for better hover effect
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "#f0f0f0", // Light gray background on hover
          },
          borderRadius: "8px", // Slightly smaller border radius
        }}
      >
        <div
          style={{
            width: "15%",
            height: "80px",
          }}
        >
          <ImageResizable
            src={result.thumbnail_url} // Updated to use the first image from main_images
            alt={result.name} // Updated alt text to project_name
            style={{
              width: "80px", // Adjusted width for better list layout
              height: "80px", // Adjusted height for better list layout
              objectFit: "cover", // Ensure the image covers the area
              borderRadius: "8px", // Slightly smaller border radius
              marginRight: "16px", // Space between image and text
              flex: "none",
            }}
            size="m"
          />
        </div>

        <Box sx={{ textAlign: "left", width: "85%" }}>
          <Typography
            variant="h6" // Changed to h6 for a more appropriate title size in a list
            sx={{
              fontWeight: "600",
              color: "#222",
              marginBottom: 0.5,
            }}
          >
            {result.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Markdown
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                lineClamp: 1,
                WebkitBoxOrient: "vertical",
              }}
            >
              {result.summary}
            </Markdown>
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};
